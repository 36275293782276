body,
html,
#root {
   height: 100%;
   margin: 0;
   overflow: hidden;
}

.app-container {
   @apply bg-secondary border border-r-secondary-500 text-primary-700;

   width: fit-content;
   position: sticky;
   top: 0;
   left: 0;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-between;
   height: 100vh;
   min-width: 90px;
   transition: width 0.3s ease;
   /* background-color: #0036af; */
   /* padding: 16px 0; */
}

.app-container.collapsed {
   width: 2rem;
}

.app-container.expanded {
   width: 15rem;
}

.sidebar {
   display: flex;
   flex-direction: column;
   color: white;
   padding: 10px;
}

.main-content {
   display: flex;
   flex-direction: column;
   height: 100%;
}

.nav-item,
.nav-item-b {
   @apply border border-transparent;

   display: flex;
   /* width: 58px; */
   height: 44px;
   justify-content: center;
   align-items: center;
   gap: 16px;
   position: relative;
   z-index: 1;
   border-radius: 4px;
   padding: 0 16px;
   transition: width 0.3s ease;
}

.nav-item.active {
   @apply bg-white border-secondary-500;
   /* background: #001686; */
}

.nav-item span,
.nav-item-b span {
   display: none;
}

.app-container.expanded .nav-item,
.app-container.expanded .nav-item-b {
   width: 12rem;
   justify-content: flex-start;
}

.app-container.expanded .nav-item span,
.app-container.expanded .nav-item-b span {
   display: block;
   white-space: nowrap;
}

.app-container .logo-nav {
   display: flex;
   justify-content: center;
   flex-direction: column;
   align-items: center;
   gap: 8px;
   padding: 5px;
}

.app-container.expanded .logo-nav {
   width: 12rem;
   flex-direction: row;
   justify-content: space-between;
}

.nav-logo {
   /* max-width: 20px;
   max-height: 23px; */
   flex-shrink: 0;
   display: flex;
   padding: 5px auto;
   justify-content: center;
   align-items: center;
   /* z-index: 2; */
}

.app-container.expanded .nav-logo {
   width: 12rem;
   justify-content: flex-start;
}

.content {
   flex-grow: 1;
}

.top-nav,
.bottom-nav {
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-bottom: 16px;
   width: 100%;
}

.top-nav {
   gap: 16px;
}

.hr-line {
   border: none;
   border-top: 1px solid #9e9e9e;
   color: #9e9e9e;
   background-color: #9e9e9e;
   width: 60px;
   height: 1px;
}

.app-container.expanded .hr-line {
   width: 13rem;
   margin-bottom: 1rem;
}

.top-nav .nav-headings,
.bottom-nav .nav-headings {
   @apply text-secondary-600;

   font-family: Roboto;
   /* color: #fff; */
   font-size: 14px;
   font-weight: 400;
   line-height: 20px;
   letter-spacing: 0.25px;
   text-align: left;
}

.nav-logo-heading {
   @apply text-primary;
   font-family: Roboto;
   font-size: 18px;
   font-weight: 500;
   line-height: 24px;
   letter-spacing: 0.15px;
   text-align: left;
   /* color: #fff; */
}

.nav-routes {
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 14px;
   width: 100%;
   overflow-y: auto;
   overflow-x: hidden;
   padding: 16px 0px;
   /* border-top: 1px solid #ffffff; */
}

.app-container.expanded .nav-routes {
   height: calc(100vh - 13rem);
}

.app-container.collapsed .nav-routes {
   height: calc(100vh - 15rem);
}

.nav-routes::-webkit-scrollbar {
   width: 8px;
   height: 8px;
   /* Width of the scrollbar */
   border-radius: 4px;
   /* Rounded corners */
}

.nav-routes::-webkit-scrollbar-track {
   background: transparent;
   /* Background color of the track */
}

.nav-routes::-webkit-scrollbar-thumb {
   background-color: #ffffff50;
   /* Color of the thumb */
   border-radius: 4px;
   /* Rounded corners */
}

.nav-routes::-webkit-scrollbar-thumb:hover {
   background-color: #001686b9;
   /* Color of the thumb on hover */
}
