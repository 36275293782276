.parent-style-date-picker {
   display: flex;
   box-sizing: border-box;
   justify-content: space-between;
   gap: 0.5rem;
}

.picker {
   @apply text-sm border border-secondary-300 rounded-md focus:border-secondary-500;
   outline: none;
   /* border: 1px solid royalblue; */
   /* border-radius: 4rem; */
   padding: 5px;
   width: 5rem;
   text-align: center;
}
