.left-login,
.right-login {
   flex: 1;
   height: 100%;
}

.left-login {
   display: flex;
   /* background-color: #0036af; */
   justify-content: center;
   align-items: center;
   flex-direction: column;
}

.right-login {
   background-color: #fff;
   display: flex;
   justify-content: center;
   align-items: center;
}

.logo-login {
   margin-bottom: 20px;
   width: 143px;
}

.excl-login {
   color: #809bd7;
}

.login-form {
   margin-top: 15px;
   display: flex;
   flex-direction: column;
   justify-content: center;
}

.input-login {
   @apply border border-secondary-300 rounded-lg px-[1rem] h-[3rem] w-full text-md font-medium box-border;
     
   background-color: transparent !important;
}

.input-login::placeholder {
   @apply text-secondary-600 text-md;
}

.password-container {
   @apply relative border border-secondary-300 rounded-lg h-[3rem] w-full text-md font-medium;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;

   box-sizing: border-box;
}

.input-password {
   @apply h-full w-full text-md font-medium pl-[1rem] pr-[3rem];
   background-color: transparent !important;

}
.password-toggle{
   @apply absolute right-[0.5rem];
}
.input-password::placeholder {
   @apply text-secondary-600 text-md;
}
.input-login:focus,
.password-container:focus-within {
   outline: 2px solid #12B97B;
 }
.input-password:focus {
   outline: none;
}
/* .input-login:-webkit-autofill,
.input-password:-webkit-autofill {
    background-color: transparent !important;  
    box-shadow: 0 0 0 30px transparent inset !important; 
    color: inherit; 
} */

.toggle-visisbilty {
   color: balck;
   margin-right: 10px;
   cursor: pointer;
}

.remember-me {
   display: flex;
   align-items: center;
}

.additional-login-details {
   @apply w-full;
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.checkbox-login {
   margin-right: 5px;
   margin-top: 4px;
   align-self: center;
   cursor: pointer;
}

.forget-password-login {
   color: #809bd7;
   cursor: pointer;
   text-decoration: none;
}

.login-submit-button {
   align-self: center;
   margin-top: 10px;
   height: 48px;
   width: 410px;
   border: 0px solid transparent;
   border-radius: 4px;
   background-color: #0036af;
}

.login-submit-button:hover {
   cursor: pointer;
   background-color: #0026aa;
}

.login-click-text {
   font-size: 14px;
   color: #fff;
   text-align: center;
   font-weight: 500;
   font-family: "Poppins", sans-serif;
}

.route-to-signup {
   font-family: "Roboto", sans-serif;
   color: #0036af;
   text-decoration: none;
}

.error-message {
   @apply w-full;
   color: red;
   font-size: 11px;
   line-height: 12px;
   text-align: center;
   display: flex;
   justify-content: center;
}

/* .parent-container-login{
  display: flex;
  flex-direction: row;
  height: 100vh;
  flex-wrap: wrap;
} */

/* .text-container {
  display: flex;
  flex-direction: column;
} */

/* .main-heading {
  text-align: center;
  font-size: 48px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  color: white;
} */

/* .sub-heading {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  color: white;
} */

/* .welcome-message {
  text-align: center;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
} */

/* .login-form-container {
  display: flex;
  flex-direction: column;
} */

/* .logo_and_texts {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
} */

/* .no-memeber {
  margin-top: 8px;
  margin-left: 5px;
  align-self: left;
} */

/* .welcome-message {
  margin-bottom: 25px;
  color: #212121;
} */

/* .detail-heading {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  color: #616161;
} */
