@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
   h1 {
      @apply text-2xl;
   }

   h2 {
      /* @apply text-xl; */
   }

   h3 {
      /* @apply text-lg; */
   }

   a {
      /* @apply text-blue-600 underline; */
   }

   dialog {
      @apply border rounded-lg z-10;
   }

   dialog::backdrop {
      background-color: hsl(250, 100%, 50%, 0.25);
   }

   /* Need to remove */
   img {
      display: inline-block;
   }

   svg {
      display: inline-block;
   }
}

body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
}

/* need to remove */
*,
::before,
::after {
   box-sizing: revert;
}

/* Input styles start */

/* main */
.btg-input {
   @apply border border-secondary-300 rounded-md text-secondary-900 focus:border-secondary-500;
}

/* variants */
.btg-basic-input {
   @apply py-2 px-3 text-md font-medium;
}

.btn-form-input {
   @apply w-full h-[3rem] !rounded-lg font-medium text-md px-4; 
}

.btn-form-input::placeholder{
   @apply text-secondary-600;
}

.btn-form-input:focus{
   @apply outline-primary; 
}

/* Input styles end */

